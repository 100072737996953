<template>
  <div id="user">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'User',
};
</script>

<style lang="scss">
@import '../styles/variables/_colors.scss';
@import '../styles/variables/_sizes.scss';
@import '../styles/theme/_default.scss';
</style>
